import './App.css';
import Navbar from './navbar';
import Header from './Header';
import Footer from './footer';
import Best from './Best';
import Menu from './Menu';
import Menuitem from './Menuitem';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
    <Helmet>
      <title>21 Pizzatown Order Now! Delivery & Takeaway</title>
      <meta name='description' content='Order pizza from 21 pizza town online and get delivered in 30 Minutes* Enjoy the best Veg/Non-veg pizza and getgreat deals/coupons online. Everyday value offer - 2 small pizza @23 zł each | 2 Big Pizza @ 29 zł each. Pizza delivery. Dine-in & Takeaway  ' />
    </Helmet>
    <Navbar/>
    <Header/>
    <Menuitem/>
    <Footer/>
   
    </>
  )
}

export default App;
