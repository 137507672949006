import React from "react";

const Footer = () => {
return <>
<footer>
    <div className=" bg_footer">
        <div className="row">
            <div className=" col-12 col-lg-12 mx-auto">
                <div className="row">
                    <div className="col-12 col-lg-12 text-center JCC2 ">
                    <ul> 
                        
                            <a >Copyright © 2022 Scopewit. All Rights Reserved. | Online Partner -  Ubereats | Bolt food | Glovo</a>
                            <div>Digital Menu <a href="http://tablefrog.com/" title="Tablefrog">Tablefrog</a> from <a href="http://tablefrog.com/" title="Tablefrog">http://tablefrog.com/</a></div>
                       
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</>
};

export default Footer;