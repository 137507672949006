import React, { useState } from 'react';
import logo1 from '../src/Image/uber.png'
import logo2 from '../src/Image/Pyszny.jpeg'
import logo3 from '../src/Image/Delivery.jpeg'
import cover from '../src/Image/Pizza.jpeg'
import scan from '../src/Image/Scan_menu.png'

const Header = () => {

  const [toggle, settoggle] = useState(true);

    const toggle1 = () => {
       
        console.log("delivery");
        settoggle(true);
        console.log(toggle);

    }
    const toggle2 = () => {
       
        console.log("pickup");
        settoggle(false);
        console.log(toggle);
    }
  
  return ( <>
    <header>
    <section>
        <div className="row">
            <div className="col-12 col-lg-6 header-left-side">
            
            </div>
            <div className="col-12 col-lg-6 header-right-side">
            
            </div>
        </div>

        <div className="row">
            <div className="col-12 col-lg-6 header-left1-side ">
            <div class="btn-group d-flex justified-content-center hpading" role="group" aria-label="Basic radio toggle button group" >
           
                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" onClick={toggle1} checked/>
                <label class="btn btn-outline-secondary" for="btnradio1">Delivery</label>
            
            
                <input type="radio" class="btn-check " name="btnradio" id="btnradio2" autocomplete="off" onClick={toggle2}/>
                <label class="btn btn-outline-secondary" for="btnradio2">Pick-up</label>

            
            </div>
         { toggle && <div className='container row'>
             <div className='box_del'>
                 <h5 className='margin_partner'>Our Delivery partner</h5>
                 <div className='col JCC '>
                     <div className='row'>
                         <div className='col-3 text-center ' >
                            <img src={logo1} width="70"alt="logo" /> 
                        </div> 
                        <div className='col text-left  JCC2'>
                             <h6> Uber Eats, Market Square</h6>
                        </div>      
                    </div>
                    <div className='line'/>
                    <div className='row '>
                         <div className='col-3 text-center ' >
                            <img src={logo2} width="70"alt="logo" /> 
                        </div> 
                        <div className='col text-left  JCC2'>
                             <h6> Pyszny.pl, Market Square</h6>
                        </div>      
                    </div>
                    <div className='line'/>
                    <div className='row'>
                         <div className='col-3 text-center ' >
                            <img src={logo3} width="70"alt="logo" /> 
                        </div> 
                        <div className='col text-left  JCC2'>   
                            <button type="button" class="btn btn-primary" onClick={(e) => { e.preventDefault(); window.location.href='https://www.restaurantlogin.com/api/fb/ee1g_j'; }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            See menu & order online
                        </button>
                        </div> 
                    </div>
                 </div>
             </div>
         </div>
         }
         <div>
         <div className='container row col-12'>
            { toggle || <div className='box_del  text-center'>
                 <h2 className='margin_partner'>Pick up</h2>
                 <h5 className='margin_partner'>Ruska 47, Wroclaw</h5>
                 <h5 className='margin_partner'>Timing</h5>
                 <p className='margin_partner'>Monday - Sunday <br/> 12:00 - 22:00</p>
                 <p className='margin_partner'>12:00 - 22:00</p>
                 <div className='col JCC '>
                     <div className='row '>
                         <div  >
                           {/*} <img src={scan} width="270"alt="logo" className='d-flex justify-content-center'/> */}
                           <div className='row text-center '>
                    

                           </div>
                        </div>      
                    </div>
                 </div>
             </div> }

         </div>
         </div>
            </div>
            <div className="col-12 col-lg-6 header-right1-side">

            <div className='col-12 text-center ' >
                            <img src={cover} width="400"alt="logo" /> 
                            <h2 className='text_bold'>We've cooked up something new</h2>
                        </div> 
               
            </div>
        </div>
    </section>
    </header>
  </> );
};

export default Header;


