 
 
const Menulist = [
 
    {
        id : 1,
        image : "./image_1/margaritta.png",
        name : "Margaritta",
        category: "veg",
        price: "22 zł",
        description : 'gouda cheese,tomato sos and oregano'
    },
  
    {
        id : 2,
        image : "./image_1/pieczarki.png",
        name : "Pieczarki",
        category: "veg",
        price: "24 zł",
        description : 'Gouda cheese ,mushroom,tomato sos and oregano'
    },
    {
        id : 3,
        image : "./image_1/cebula.png",
        name : "Cebula ",
        category: "veg",
        price: "24zł",
        description : 'gouda cheese ,onion and sos'
    },
  {
        id : 4,
        image : "./image_1/spinach.png",
        name : "Spinach",
        category: "veg",
        price: "24 zł",
        description : 'gouda cheese ,tomato sos,spinach and oregano'
    },
  
  
  
  
  
  
 {
        id : 5,
        image : "./image_1/greenland.png",
        name : "Grandland",
        category: "veg",
        price: "26 zł",
        description : 'Gouda cheese ,tomato sos, spinach ,brocoli and oregano'
    },
  
 {
        id : 6,
        image : "./image_1/veg_speciall.png",
        name : "veg speciall",
        category: "veg",
        price: "32 zł",
        description : 'gouda cheese ,tomato sos, spinach, mushroom, paprika, oliven, corn, ruccola, oregano'
    },
  
 {
        id : 7,
        image : "./image_1/salami.png",
        name : "salami",
        category: "Non-veg",
        price: "33 zł",
        description : 'gouda cheese ,tomato sos,salami and oregano'
    },
  
 {
        id : 8,
        image : "./image_1/schinken.png",
        name : "schinken",
        category: "non-veg",
        price: "33 zł",
        description : 'gouda cheese ,tomato sos,schinken and oregano'
    },
  
  
  
  
  
  
 {
        id : 9,
        image : "./image_1/Prosciutto.png",
        name : "Prosciutto",
        category: "non-veg",
        price: "36 zł",
        description : 'gouda cheese ,tomato sos,salami,schinken and oregano'
    },
  
 {
        id : 10,
        image : "./image_1/Hawaii.png",
        name : "Hawaii",
        category: "non-veg",
        price: "32 zł",
        description : 'gouda cheese ,tomato sos,schinken, Ananas and oregano'
    },
  
 {
        id : 11,
        image : "./image_1/Alaska.png",
        name : "Alaska",
        category: "Non-veg",
        price: "36 zł",
        description : 'gouda cheese ,tomato sos,tuna ,cebula and oregano'
    },
  
 {
        id : 12,
        image : "./image_1/gyros_Pizza.png",
        name : "Gyros Pizza ",
        category: "non-veg",
        price: "36 zł",
        description : 'gouda cheese ,tomato sos,swineflisch ,cebula and oregano'
    },
  
 {
        id : 14,
        image : "./image_1/Pizza_salmon.png",
        name : "salmon",
        category: "non-veg",
        price: "37 zł",
        description : 'gouda cheese ,tomato sos,salmon,garlic and oregano'
    },
  
 {
        id : 15,
        image : "./image_1/green_salmon.png",
        name : "green salmon",
        category: "non-veg",
        price: "37 zł",
        description : 'gouda cheese ,tomato sos,salmon,garlic, spinach, rucola  and oregano'
    },
  
 {
        id : 16,
        image : "./image_1/frutti_di_mare.png",
        name : "frutti di mare",
        category: "non-veg",
        price: "38 zł",
        description : 'gouda cheese ,tomato sos, prawns, calamari, mussels, garlic and oregano'
    },
  
 {
        id : 17,
        image : "./image_1/scampi.png",
        name : "scampi",
        category: "non-veg",
        price: "38 zł",
        description : 'gouda cheese ,tomato sos,prawns,garlic and oregano'
    },
  
  
 {
        id : 18,
        image : "./image_1/hackfleisch.png",
        name : "Pizza Hackfleisch",
        category: "non-veg",
        price: "39zł",
        description : 'gouda cheese ,tomato sos, beef minced, cebula, bacon  and oregano'
    },
  
 {
        id : 19,
        image : "./image_1/Milono.png",
        name : "Pizza Milono",
        category: "non-veg",
        price: "35 zł",
        description : 'gouda cheese ,tomato sos, Schinken,Mushroom and oregano'
    },
  
 {
        id : 20,       
        image : "./image_1/Pizza_Chef .png",
        name : "Pizza Chef ",
        category: "Non-veg",
        price: "39 zł",
        description : 'gouda cheese ,tomato sos, spinach, mushroom, schinken, paprika, tuna fish, egg, cebula, garlic and oregano'
    },
  
 {
        id : 21,
        image : "./image_1/Pizza_Newyorker.png",
        name : "Pizza New Yorker",
        category: "American",
        price: "35 zł",
        description : 'gouda cheese, mozzarella cheese, BBQ sos, Chicken, cebula, jalapeno and oregano'
    },
 {
        id : 22,
        image : "./image_1/Pizza_Miami.png",
        name : "Pizza Miami",
        category: "American",
        price: "37 zł",
        description : 'gouda cheese,mozzarella cheese ,BBQ sos,beef minced,cebula, and oregano'
    },
 {
        id : 23,
        image : "./image_1/Pizza_Florida.png",
        name : "Pizza Florida",
        category: "American",
        price: "37zł",
        description : 'gouda cheese, mozzarella cheese, BBQ sos, broccoli, garlic, speck, cebula, beans and oregano'
    },
 {
        id : 24,
        image : "./image_1/Pizza_Mexico.png",
        name : "Pizza Mexico",
        category: "American",
        price: "39 zł",
        description : 'gouda cheese, mozzarella cheese ,BBQ sos, Chicken, beans, bacon, beef minced, cebula, jalapeno, cukkini and oregano'
    },
 {
        id : 25,
        image : "./image_1/Pizza_Chicken_Tikka_masala.png",
        name : "Pizza Chicken tikka masala",
        category: "Indian",
        price: "38 zł",
        description : 'gouda cheese,mozzarella cheese ,Tikka sos, Roasted Chicken,cebula,paprika,mint mayo and oregano'
    },
 {
        id : 26,
        image : "./image_1/Pizza_Paneer_Tikka masala.png",
        name : "Pizza Paneer tikka masala",
        category: "Indian",
        price: "33 zł",
        description : 'gouda cheese,mozzarella cheese ,Tikka sos, Roasted paneer ,paprika,mint mayo and oregano'
    },
 {
        id : 27,
        image : "./image_1/Pizza_Butter_Chicken.png",
        name : "Pizza Butter  Chicken",
        category: "Indian",
        price: "38 zł",
        description : 'gouda cheese,mozzarella cheese ,butter chicken sos, Roasted Chicken,cebula,paprika, and oregano'
    },
 {
        id : 28,
        image : "./image_1/Pizza_butter_paneer.png",
        name : "Pizza Butter Paneer",
        category: "Indian",
        price: "35 zł",
        description : 'gouda cheese,mozzarella cheese ,tomato cream sos,Paneer,paprika, and oregano'
    },
 {
        id : 29,
        image : "./image_1/Sweet_corn.png",
        name : "Pizza Sweet Corn",
        category: "Indian",
        price: "31 zł",
        description : 'Creamy tomato Sos, mozzarella cheese, Gouda Cheese, Sweet corn.',
    },
    {
        id : 30,
        image : "./image_1/seek_kebab.png",
        name : "Pizza Chicken Seek",
        category: "Indian",
        price: "35 zł",
        description : 'Tandoori Sos, Gouda Cheese, Chicken grilled seek (minced of chicken mixed with spices and roasted in grills ) , black seed, Chilli flakes..'
    },
    {
        id : 31,
        image : "./image_1/Non_veg_suprim.png",
        name : "Pizza Continental Farm",
        category: "Indian",
        price: "35 zł",
        description : 'Creamy Tomato Sos, Mushroom, black olives, capsicum, Onion, Chicken, nigella seeds.'
    },
    {
        id : 32,
        image : "./image_1/Tandoori_veg.png",
        name : "Pizza Veggi Tandoori",
        category: "Indian",
        price: "33 zł",
        description : 'Tandoori Sos, Gouda cheese, Capsicum, Red chilli, Corns, Black olives.'
    },
    {
        id : 33,
        image : "./image_1/Tabdoori_chicken.png",
        name : "Pizza Tandoori Chicken",
        category: "Indian",
        price: "36 zł",
        description : 'Tandoori sos, Gouda Cheese, Capsicum, Corn, black olives, onion'
    },
    {
        id : 34,
        image : "./image_1/Pizza_Newyorker.png",
        name : "Vegan Veggi",
        category: "vegan",
        price: ".. zł",
        description : 'Comming Soon'
    },
    {
        id : 35,
        image : "./image_1/Pizza_Newyorker.png",
        name : "Vegan Calzona",
        category: "vegan",
        price: "..zł",
        description : 'Comming soon'
    },
    {
        id : 36,
        image : "./image_1/pizza-calzone.png",
        name : "Veggi Calzona (30cm)",
        category: "calzona",
        price: "36 zł",
        description : 'Tandoori sos, Gouda Cheese, Capsicum, Corn, black olives, onion'
    },
    {
        id : 37,
        image : "./image_1/pizza-calzone.png",
        name : "Calzona Special(30cm)",
        category: "calzona",
        price: "30 zł",
        description : 'tomato sos, tuna, ham, mushroom, egg, cheese'
    },
    {
        id : 38,
        image : "./image_1/pizza-calzone.png",
        name : "Makhni Calzona(30cm)",
        category: "calzona",
        price: "29 zł",
        description : 'Creamy tomato sos, paneer, paprika, cheese'
    },
    {
        id : 39,
        image : "./image_1/pizza-calzone.png",
        name : "Tikka Masala Calzona(30cm)",
        category: "calzona",
        price: "29 zł",
        description : 'tikka sos, paneer, paprika, cheese, olives'
    },
    {
        id : 40,
        image : "./image_1/bowl_1.png",
        name : "Delhi Delit",
        category: "bowl",
        price: "22.60 zł",
        description : 'Avocado, Toasted panner, Broccoli, Oats rice, Nuts, Makhni sos, garlic sos'
    },
    {
        id : 41,
        image : "./image_1/bowl_1.png",
        name : "Mumbai Masala",
        category: "bowl",
        price: "22.60 zł",
        description : 'Veg Pakora, Carrot, Broccoli, cucumber, Capsicum, Olives, Nuts, Oats, Tikka sos, Garlic Sos'
    },
    {
        id : 42,
        image : "./image_1/bowl_1.png",
        name : "Chennai Express",
        category: "bowl",
        price: "22.60 zł",
        description : 'Carrot, chickpeas, Cucumber, soya roots, mix Salata, boiled egg, Garlic sos'
    },
    {
        id : 43,
        image : "./image_1/bowl_1.png",
        name : "Bengal Sweet",
        category: "bowl",
        price: "22.60 zł",
        description : 'Pineapple, Apple, tosted paneer, red cabbage, beetroot, cherry tomato, Oats rice, Avocado'
    },
    {
        id : 44,
        image : "./image_1/Oreo_puff.png",
        name : "Oreo Puff",
        category: "desert",
        price: "4.99 zł",
        description : 'Oreo, nutella, baked in oven'
    },
    {
        id : 45,
        image : "./image_1/Coconut_puff.png",
        name : "Coconut Puff",
        category: "desert",
        price: "4.99 zł",
        description : 'Coconut, nutella, baked in oven'
    },
    {
        id : 46,
        image : "./image_1/Carrot_Puff.png",
        name : "Red Carrot Puff",
        category: "desert",
        price: "4.99 zł",
        description : 'halwa, carrot, baked in oven'
    },
    {
        id : 47,
        image : "./image_1/fries.png",
        name : "Fries",
        category: "snacks",
        price: "7.90 zł",
        description : 'deep fried potato chips'
    },
    {
        id : 48,
        image : "./image_1/rings.png",
        name : "Onion Rings",
        category: "snacks",
        price: "10.90 zł",
        description : 'Deep fried onion rings'
    },
    {
        id : 49,
        image : "./image_1/chicken-wings.png",
        name : "BBQ Chicken Wings",
        category: "snacks",
        price: "25 zł",
        description : 'baked in oven'
    },
    {
        id : 50,
        image : "./image_1/grill.png",
        name : "Chicken Wings",
        category: "snacks",
        price: "20 zł",
        description : 'Baked in oven'
    },
    {
        id : 51,
        image : "./image_1/stick.png",
        name : "Mozzerela Sticks",
        category: "snacks",
        price: "10.90 zł",
        description : 'Deep fried Sticks'
    },
    {
        id : 52,
        image : "./image_1/Chicken_Nuggets.png",
        name : "Chicken Nuggets",
        category: "snacks",
        price: "10.90 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 53,
        image : "./image_1/cola.png",
        name : "Coco-cola",
        category: "drinks",
        price: "5 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 54,
        image : "./image_1/Pepsi.png",
        name : "Pepsi",
        category: "drinks",
        price: "5 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 55,
        image : "./image_1/7up.png",
        name : "7 up",
        category: "drinks",
        price: "5 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 56,
        image : "./image_1/Fanta_1.png",
        name : "fanta",
        category: "drinks",
        price: "5 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 57,
        image : "./image_1/mango_lassi.png",
        name : "Mango Lassi",
        category: "drinks",
        price: "12 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 58,
        image : "./image_1/Masala_chai.png",
        name : "Masala Chai",
        category: "drinks",
        price: "12 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 59,
        image : "./image_1/liche.png",
        name : "Lichi Sok",
        category: "drinks",
        price: "7 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 60,
        image : "./image_1/Gurva.png",
        name : "Gouva Sok",
        category: "drinks",
        price: "7 zł",
        description : 'Deep fried Nuggets'
    },
    {
        id : 61,
        image : "./image_1/Rosemilk.png",
        name : "Rose Milk",
        category: "drinks",
        price: "12 zł",
        description : 'Deep fried Nuggets'
    },
  
 ]
  
 export default Menulist;
 
 