import React from 'react';
import logo1 from '../src/Image/logo3.png'

const Navbar = () => {
  return <>
      <nav class="navbar">
        
     <div class="container-fluid justify-content-center">
         <a class="navbar-brand heading ">21 Pizza Town</a>
    </div>
    <div class="container-fluid justify-content-center">
    <img src={logo1} width="90"alt="logo" className="img_nav" />
    </div>
</nav>
  </>;
};

export default Navbar;
