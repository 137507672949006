import React ,{useState} from 'react';

import Menulist from './Menulist';

const Menuitem = () => {

  const [items, setitems] = useState(Menulist);

  const filterItem = (categItem) => {
    const updateItem = Menulist.filter((curElem ) => {
      return curElem.category === categItem;

    });
    setitems(updateItem);
  }



  return ( <>
            <div className="row text-center mid_padding" >
                      <div className='line1'></div>
                      <h2 className='heading'>Our Menu</h2>
                      <div className='line1'></div>
            </div>

            <div className='menu-tabs container '> 
              <div className='menu-tab d-flex justify-content-around'>
                <button className='btn btn-warning' onClick={() => filterItem('veg')}> Veggi Pizza</button>
                <button className='btn btn-warning' onClick={() => filterItem('non-veg')}> Meat Pizza</button>
                <button className='btn btn-warning' onClick={() => filterItem('American')}> American Pizza</button>
                <button className='btn btn-warning' onClick={() => filterItem('Indian')}> Indian Pizza</button>
                <button className='btn btn-warning' onClick={() => filterItem('vegan')}> Vegan Pizza</button>
                <button className='btn btn-warning' onClick={() => filterItem('calzona')}> Calzona </button>
                <button className='btn btn-warning' onClick={() => filterItem('bowl')}> Indian Bowl </button>
                <button className='btn btn-warning' onClick={() => filterItem('desert')}> Desert </button>
                <button className='btn btn-warning' onClick={() => filterItem('snacks')}> Snacks </button>
                <button className='btn btn-warning' onClick={() => filterItem('drinks')}> Drinks </button>

              </div>
            </div>

        <div className='menu-item container-fluid mt-5'>
        <div className='row'>
        <div className='col-11 mx-auto'>
        <div className='row my-5 '>

         

        {
          items.map((elem) => {
            const {id, name, image, price, description} = elem;
            return ( 
                        
                         <div className='item1 col-12 col-md-6 col-lg-6 col-xl-4  d-flex justify-content-center shadow'>
                            <div className='row  '>
                                <div className='col-12 col-md-12 col-lg-4 img-div d-flex justify-content-center'> 
                                 <img src={image} alt="image" className='img-fluid' />
                                </div>
                            <div className='col-12 col-md-12 col-lg-8 '>
                              
                              <div className='main-title pt-2 pb-2'>
                                <h3> {name}</h3>
                                <p>{description}</p>
                              </div>
                              <div className='menu-price-book'>
                                <div className='price-book-div'>
                                <h5>{price}</h5>
                              </div>
                              
                              </div>
                            </div>
                          </div>
                        </div> 
                        
                         
                      )
                    })
                  }      
        </div>
      </div>
    </div>
  </div>


  </>)
}

export default Menuitem;
